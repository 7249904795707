import {
    ConfigApi,
    createApiRef,
    DiscoveryApi,
    FetchApi,
} from '@backstage/core-plugin-api';

type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  fetchApi: FetchApi;
};

export interface GitDiffStats {
  additions: number;
  deletions: number;
  totalChanges: number;
  files: number;
  error?: string;
  message?: string;
}

export const giteaApiRef = createApiRef<GiteaAPI>({
  id: 'plugin.gitea.service',
});

export class GiteaAPI {
  private readonly backendUrl: string | undefined;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.backendUrl = options.configApi.getOptionalString('backend.baseUrl');
    this.fetchApi = options.fetchApi;
  }

  private async getBaseUrl() {
    return `${this.backendUrl}/api/gitea/proxy`;
  }

  /**
   * Get diff statistics for a pull request
   */
  async getPRDiffStats(owner: string, repo: string, prNumber: string | number): Promise<GitDiffStats> {
    const baseUrl = await this.getBaseUrl();
    const apiUrl = `${baseUrl}/pr/${owner}/${repo}/${prNumber}/linestats`;
    
    const response = await this.fetchApi.fetch(apiUrl, {
      headers: {
        'Accept': 'application/json',
      },
    });
    
    if (!response.ok) {
      throw new Error(`Failed to fetch PR code diff: ${response.statusText}`);
    }
    
    return response.json();
  }
}
