import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import {
  ProjectColumnResponse,
  ProjectRequest,
  ProjectResponse,
  TagRequest,
  Task,
  TaskRequest,
  TaskResponse,
  TaskTransactionResponse,
  UserRequest,
  UserResponse,
} from '../types';

type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  fetchApi: FetchApi;
};

export const sev2ApiRef = createApiRef<Sev2API>({
  id: 'plugin.sev2.service',
});

export class Sev2API {
  private readonly backendUrl: string | undefined;
  private readonly fetchApi: FetchApi;
  private readonly confApi: ConfigApi;

  constructor(options: Options) {
    this.backendUrl = options.configApi.getOptionalString('backend.baseUrl');
    this.confApi = options.configApi;
    this.fetchApi = options.fetchApi;
  }

  private async getUrls() {
    return {
      apiUrl: `${this.backendUrl}/api/sev2/proxy/`,
    };
  }

  getSev2Url(): string {
    return (
      this.confApi.getOptionalString('integrations.sev2.host') ||
      'https://refactory.sev-2.com'
    );
  }

  async getTasks(params: TaskRequest): Promise<TaskResponse> {
    const { apiUrl } = await this.getUrls();

    const {
      projectPHID = '',
      limit = '100',
      before = '',
      after = '',
      tagPHID = '',
    } = params;

    const reqBodyObj: Record<string, any> = {
      'constraints[projects][0]': projectPHID,
      'attachments[projects]': '1',
      'attachments[logs]': '1',
      limit: limit,
      before: before,
      after: after,
    };

    if (tagPHID) {
      const reqBodyObjKey = `constraints[projects][1]`;
      reqBodyObj[reqBodyObjKey] = tagPHID;
    }

    const reqBody = JSON.stringify(reqBodyObj);
    const request = await this.fetchApi.fetch(`${apiUrl}maniphest.search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: reqBody,
    });

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    return request.json();
  }

  async getProject(params: ProjectRequest): Promise<ProjectResponse> {
    const { apiUrl } = await this.getUrls();
    const { projectIds } = params;

    const body = JSON.stringify({
      'constraints[ids][0]': projectIds,
    });

    const request = await this.fetchApi.fetch(`${apiUrl}project.search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    return request.json();
  }

  async getTags(params: TagRequest): Promise<ProjectResponse> {
    const { apiUrl } = await this.getUrls();
    const { tagName } = params;

    const reqBodyObj: Record<string, any> = {};
    const reqBodyObjKey = `constraints[name]`;
    reqBodyObj[reqBodyObjKey] = tagName;
    reqBodyObj['constraints[icons][0]'] = ['tag'];
    const body = JSON.stringify(reqBodyObj);

    const request = await this.fetchApi.fetch(`${apiUrl}project.search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    return request.json();
  }

  async getTaskByProjectBoard(params: TaskRequest): Promise<TaskResponse> {
    const {
      after = '',
      before = '',
      limit = '10',
      projectBoardUrl = '',
      tagName = '',
    } = params;

    const projectIds = projectBoardUrl.replace(/.*\/(\d+)\/?$/, '$1');

    const project = await this.getProject({
      projectIds: projectIds,
    });

    const tagId = await this.getTags({
      tagName,
    });

    const tagPHID = tagId?.result?.data.find(
      it => it?.fields.name === tagName,
    )?.phid;

    const getTaskPayload = {
      projectPHID: project?.result?.data?.at(0)?.phid,
      tagPHID: tagPHID,
      after,
      before,
      limit,
    };

    const tasks = await this.getTasks(getTaskPayload);

    if (!tasks) {
      throw new Error(`failed to fetch data, status `);
    }
    return tasks;
  }

  async getAllTaskByProjectBoard(params: TaskRequest): Promise<Task[]> {
    const {
      after = '',
      before = '',
      limit = '100',
      projectBoardUrl = '',
      tagName = '',
    } = params;

    const projectIds = projectBoardUrl.replace(/.*\/(\d+)\/?$/, '$1');

    const project = await this.getProject({
      projectIds: projectIds,
    });

    const tagId = await this.getTags({
      tagName,
    });

    const tagPHID = tagId?.result?.data.find(
      it => it?.fields.name === tagName,
    )?.phid;

    const getTaskPayload = {
      projectPHID: project?.result?.data?.at(0)?.phid,
      tagPHID: tagPHID,
      after,
      before,
      limit,
    };

    const tasks = await this.getTasks(getTaskPayload);

    if (!tasks) {
      throw new Error(`failed to fetch data, status `);
    }

    const mergedTask: Task[] = [...tasks.result.data];
    let nextCursor = tasks.result.cursor?.after;

    while (nextCursor) {
      const nextPayload = { ...getTaskPayload, after: nextCursor }; // Create a new payload with updated cursor
      const nextTasks = await this.getTasks(nextPayload);

      if (!nextTasks || !nextTasks.result?.data) {
        throw new Error('Failed to fetch next batch of data');
      }

      mergedTask.push(...nextTasks.result.data);
      nextCursor = nextTasks.result.cursor?.after; // Update the cursor
    }
    return mergedTask;
  }

  async convertRemarkupToHtml(remarkupText: string): Promise<string> {
    const { apiUrl } = await this.getUrls();

    const reqBodyObj: Record<string, any> = {
      context: 'phriction',
      'contents[0]': remarkupText,
    };

    const response = await this.fetchApi.fetch(`${apiUrl}remarkup.process`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBodyObj),
    });

    if (!response.ok) {
      throw new Error(
        `Failed to convert Remarkup, status ${response.status}: ${response.statusText}`,
      );
    }

    const data = await response.json();

    if (data.result.length === 0) {
      throw new Error('No content found in the response');
    }

    return data.result[0].content || '';
  }

  async getFeedbackData(projectId?: string): Promise<Task[]> {
    try {
      const { apiUrl } = await this.getUrls();

      const defaultProjectId = 'PHID-PROJ-tzqrxusiw5mhur2vxqae';
      const feedbackProjectId =
        this.confApi.getOptionalString('integrations.sev2.feedbackProjectId') ||
        projectId ||
        defaultProjectId;

      const reqBodyObj = {
        'constraints[projects][0]': feedbackProjectId,
        order: 'newest',
      };

      const request = await this.fetchApi.fetch(`${apiUrl}maniphest.search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBodyObj),
      });

      if (!request.ok) {
        return [];
      }

      try {
        const response = await request.json();

        if (!response || !response.result || !response.result.data) {
          return [];
        }

        return response.result.data;
      } catch (jsonError) {
        return [];
      }
    } catch (error) {
      return [];
    }
  }

  async getUser(params: UserRequest): Promise<UserResponse> {
    const { apiUrl } = await this.getUrls();
    const { phids } = params;

    const reqBodyObj: Record<string, any> = {};

    const reqBodyObjKey = `phids[0]`;
    reqBodyObj[reqBodyObjKey] = phids;

    const body = JSON.stringify(reqBodyObj);

    const request = await this.fetchApi.fetch(`${apiUrl}user.query`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const data = await request.json();

    if (data.result.length === 0) {
      throw new Error('No content found in the response');
    }

    return data.result[0];
  }

  async getTaskTransactions(taskId: number): Promise<TaskTransactionResponse> {
    const { apiUrl } = await this.getUrls();

    const reqBodyObj: Record<string, any> = {};

    const reqBodyObjKey = `ids[0]`;
    reqBodyObj[reqBodyObjKey] = taskId;

    const body = JSON.stringify(reqBodyObj);

    const request = await this.fetchApi.fetch(
      `${apiUrl}maniphest.gettasktransactions`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      },
    );

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const data = await request.json();

    return data.result[taskId];
  }

  async getProjectColumn(projectPHID: string): Promise<ProjectColumnResponse> {
    const { apiUrl } = await this.getUrls();

    const reqBodyObj: Record<string, any> = {};

    const reqBodyObjKey = `constraints[projects][0]`;
    reqBodyObj[reqBodyObjKey] = projectPHID;

    const body = JSON.stringify(reqBodyObj);
    const request = await this.fetchApi.fetch(
      `${apiUrl}project.column.search`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      },
    );

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    const data = await request.json();

    return data;
  }
}
